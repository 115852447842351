import React, { useState } from 'react';
import Modal from 'react-modal';

export const Image = ({ title, largeImage, smallImage }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)', // This will give a semi-transparent black overlay
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: '#000000',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    image: {
      maxWidth: '75vw', // Limit the width to 90% of the viewport width
      maxHeight: '75vh', // Limit the height to 90% of the viewport height
      objectFit: 'contain', // Keep aspect ratio while scaling the image
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: 'black',
      border: 'none',
      color: 'white',
      fontSize: '1.5em',
    },
  };

  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        <div onClick={openModal}>
          <div className="hover-text">
            <h4>{title}</h4>
          </div>
          <img src={smallImage} className="img-responsive" alt={title} />
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Modal"
          style={customStyles}
        >
          <img src={largeImage} className="img-responsive" alt={title} style={customStyles.image} />
          <button style={customStyles.closeButton} onClick={closeModal}>X</button>
        </Modal>
      </div>
    </div>
  );
};