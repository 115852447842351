import React from 'react';

const DePayComponent = () => {
    return (
        <div id="payment" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>
                        Purchase
                    </h2>
                </div>
                <div className="col-md-8 col-md-offset-2">
                    <p>
                        Our software is subscription based, paid only via cryptocurrency. <br />
                        Refunds are available within 24 hours of purchase, minus any cryptocurrency transaction fees. <br />
                        Access to software is immediate upon payment.
                    </p>
                    <p>
                        Please note: This software will only work on Windows 10 and 11, and on the Steam version ONLY.
                    </p>

                    <p>
                        To purchase our software, simply click the DePay logo below.
                        You'll be guided through a secure and convenient crypto payment process.
                    </p>
                    <h3>
                        30 Day subscription: $30 USD
                    </h3>
                    <a href="https://link.depay.com/4DoS6JjgIgzECAxzTxS2GK" target="_blank" rel="noopener noreferrer">
                        <img className='img' src='/img/DePay-Logo.png' alt="Pay with DePay" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default DePayComponent;
